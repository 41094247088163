import "./App.css";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import TeamPage from "./pages/TeamPage";
import Redirect from "./components/Redirect";
import ContactPage from "./pages/ContactPage";
import TestingPage from "./pages/TestingPage";
import { WixPage } from "./components/WixPage";
import NotFoundPage from "./pages/NotFoundPage";
import GyaanExamPage from "./pages/GyaanExamPage";
import CommunityPage from "./pages/CommunityPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import DownloadAppPage from "./pages/DownloadAppPage";
import PremiumAdminPage from "./pages/PremiumAdminPage";
import { whatsappCommunityUrl } from "./utils/Constants";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
// import HomePageWix from "./pages/HomePageWix";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import PremiumGyaanPage from "./pages/PremiumGyaanPage";

const PageWrapper = () => {
  // Made because useLocation can only be used inside <Router />
  const activePage = useLocation().pathname;
  const headerExcludePages = [
    "/premium",
    "/premium-in",
    "/remind",
    "/gyaan-exam-page",
    "/premium-admin",
    "/premium-gyaan",
    "/download-gyaan-app",
    "/download-livorn-app",
  ];
  const showHeader = !headerExcludePages.includes(activePage);

  return (
    <>
      {showHeader && <Header />}
      <Routes>
        {/* Website Pages */}
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/" element={<HomePageWix />} /> */}
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/testing" element={<TestingPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/whatsapp-redirect" element={<Redirect url={whatsappCommunityUrl} />} />

        {/* Livorn App */}
        <Route path="/premium-admin" element={<PremiumAdminPage />} />
        <Route path="/premium-gyaan" element={<PremiumGyaanPage />} />
        <Route path="/gyaan-exam-page" element={<GyaanExamPage />} />

        {/* Wix Pages */}
        <Route path="/premium" element={<WixPage url="premium" />} />
        <Route path="/premium-in" element={<WixPage url="premium/india" />} />
        <Route path="/remind" element={<WixPage url="remind" mode="old" marginTop={-40} />} />

        {/* Download App Links */}
        <Route path="/download-gyaan-app" element={<DownloadAppPage app="Gyaan" />} />
        <Route path="/download-livorn-app" element={<DownloadAppPage app="Livorn" />} />
      </Routes>
    </>
  );
};

// Complete App
const App = () => {
  return (
    <Router>
      <PageWrapper />
    </Router>
  );
};

export default App;
