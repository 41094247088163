export const sendWhatsappMessage = async (
  message,
  phoneNumber,
  failureUrl = "https://www.livorn.in/contact-us"
) => {
  const encodedMessage = encodeURIComponent(message);
  const waMeUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  const apiWhatsAppUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

  try {
    // Attempt to open the wa.me link
    window.open(waMeUrl, "_blank");
  } catch (error) {
    console.log("Error opening wa.me:", error);
    try {
      // If wa.me fails, attempt to open the api.whatsapp.com link
      window.open(apiWhatsAppUrl, "_blank");
    } catch (error) {
      console.log("Error opening api.whatsapp.com:", error);
      // If both fail, open the provided failureUrl
      window.open(failureUrl, "_blank");
    }
  }
};
