import React from "react";
import PrimaryButton from "../components/PrimaryButton";
import { gyaanPlayStoreUrl, playStoreUrl } from "../utils/Constants";

const DownloadAppPage = ({ app }) => {
  const handlePress = () => {
    app === "Gyaan" && window.open(gyaanPlayStoreUrl, "_blank");
    app === "Livorn" && window.open(playStoreUrl, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          height: 300,
          backgroundColor: "#44444490",
          borderRadius: 30,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../assets/PlaystoreIcon.png")}
          style={{ width: 100, height: 100, marginBottom: 20, marginLeft: 20 }}
          alt="Logo"
        />
        <h4 style={{ fontSize: 20 }}>Google Playstore</h4>
        <PrimaryButton title={`Download ${app} AI`} onClick={handlePress} />
      </div>
    </div>
  );
};

export default DownloadAppPage;
