// TODO => Later import updated file from Livorn actual project
const Colors = {
  livornPrimary: "#1b98f5",
  livornSecondary: "#0742a0",
  activeWhite: "rgba(255, 255, 255, 0.85)",
  translucentWhite: "rgba(255, 255, 255, 0.20)",
  disabledWhite: "#696969",
  primaryTouchFeedback: "#444444",
  backgroundPrimary: "#000000",
  backgroundSecondary: "#161618", // #121212 More Dark
  lineColor: "#101010",
  buttonAndIconColor: "#1f1f1f",
  myLifeVoilet: "#6050dc",
  missionOrange: "#ff6700",
  savedGreen: "#228b22",
  healthRed: "#eb0000", // "#ff0000" More Light Red
  notesYellow: "#f4c430",
  clockBlue: "#23aef1",
  progressBar: "#32cd32",
  premiumGoldColor: "#ffdf00",
  loadingColor: "#2e0f0f",
  reminderColor: "#FF5757",
  habitColor: "#4caf50",
};

export default Colors;

// Input Hex Code and opacity from 0-1
export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  return `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${alpha})`;
};

export const getDarkShade = (hexColor) => {
  // Remove '#' if present
  hexColor = hexColor.replace("#", "");

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate 90% opacity of the color
  const opacity = 0.9;

  // Calculate darker shade by reducing the lightness
  const darkerR = Math.floor(r * (1 - opacity));
  const darkerG = Math.floor(g * (1 - opacity));
  const darkerB = Math.floor(b * (1 - opacity));

  // Convert RGB to hex
  const darkerHex = `#${((1 << 24) + (darkerR << 16) + (darkerG << 8) + darkerB)
    .toString(16)
    .slice(1)}`;

  return darkerHex;
};
