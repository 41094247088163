import React, { useState } from "react";
import styles from "../styles/PremiumGyaanPage.module.css";
import { sendWhatsappMessage } from "../functions/sendWhatsappMessage";
import Section from "../components/Section";
import { useSyncWithFirebase } from "../utils/firebase";

const PriceBox = ({ pricing }) => {
  return (
    <div className={styles.priceBox}>
      <div className={styles.priceBoxLeft}>
        <h3 className={styles.timeHeading}>{pricing?.title}</h3>
        <h5 className={styles.perDayHeading}>{pricing?.pricePerDay}</h5>
      </div>
      <div className={styles.priceBoxRight}>
        <h1 className={styles.newPrice}>₹{pricing?.price}</h1>
        <h1 className={styles.oldPrice}>₹{pricing?.fullPrice}</h1>
      </div>
    </div>
  );
};

const Feature = ({ feature }) => {
  return (
    <div className={styles.feature}>
      <h3 className={styles.icon}>✔</h3>
      <h3 className={styles.featureHeading}>{feature}</h3>
    </div>
  );
};

const UpgradeButton = () => {
  return (
    <div className={styles.mobileDownloadContainer}>
      <div
        className={styles.mobileDownloadButton}
        onClick={() => sendWhatsappMessage("I want to Upgrade to Gyaan AI Pro", "918168278142")}
      >
        <h3 className={styles.upgradeHeading}>Upgrade to Pro</h3>
      </div>
    </div>
  );
};

const PremiumGyaanPage = () => {
  const [gyaanPricing, setGyaanPricing] = useState({});
  useSyncWithFirebase({ path: "updates/gyaanPricing", setFunction: setGyaanPricing });

  // Default Pricing
  // {
  //   "monthly": {
  //     "price": 97,
  //     "fullPrice": 199,
  //     "pricePerDay": "₹3.3 per day",
  //     "title": "Monthly"
  //   },
  //   "yearly": {
  //     "price": 997,
  //     "fullPrice": 2399,
  //     "pricePerDay": "₹2.7 per day",
  //      "title": "Yearly"
  //   }
  // }

  return (
    <Section paddingTop={0} background={require("../assets/PremiumBackground.png")}>
      <div className={styles.container}>
        {/* Price Alert */}
        <div className={styles.priceAlert}>
          <h3 className={styles.alertHeading}>Hurry! Prices will increase soon</h3>
        </div>

        {/* Price Boxes */}

        <PriceBox pricing={gyaanPricing?.monthly} />
        <PriceBox pricing={gyaanPricing?.yearly} />

        {/* Benefits */}
        <h3 className={styles.benefits}>Your Benefits</h3>
        <div className={styles.featureContainer}>
          {features.map((feature, index) => (
            <Feature key={index} feature={feature} />
          ))}
        </div>

        {/* Upgrade Button */}
        <UpgradeButton />
      </div>
    </Section>
  );
};

export default PremiumGyaanPage;

const features = [
  "Ask unlimited concepts and doubts",
  "Access an AI personal tutor for 20+ subjects",
  "Create and practice unlimited tests",
  "Attempt 550+ previous year exams unlimited times",
  "Check and evaluate unlimited exam papers",
  "Sync data across multiple devices",
  "Get early access to topper's notes",
  "Ask unlimited doubts using the camera and mic",
  "Get 24/7 priority support",
];
